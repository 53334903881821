import axios from 'axios';
import { toast } from 'react-toastify';
import { APP_URL } from '../URL';

export const fetchStatistics = () => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token')
        if (!token) {
            throw new Error('Token non trouvé');
          }
        const response = await axios.get(APP_URL+'statistiques/chiffres',{
            headers: {
                'Content-Type': 'application/ld+json',
                'Authorization': `Bearer ${token}`, // Assurez-vous que votre API utilise "Bearer" suivi du jeton
              },
        });
        dispatch({
          type: 'FETCH_STATISTICS_SUCCESS',
          payload: response.data,
        });
      } catch (error) {
        if (error.response) {
          // La requête a été faite, mais le serveur a répondu avec un code d'état différent de 2xx
          console.error('Réponse du serveur avec erreur:', error.response.data);
          console.error('Statut de la réponse:', error.response.status);
          console.error('En-têtes de la réponse:', error.response.headers);
      
          dispatch({
            type: 'FETCH_STATISTICS_FAILURE',
            payload: error.response.data,
          });
      
          // Afficher une notification d'échec
          toast.error('Erreur lors de la lecture des statistics');
        } else if (error.request) {
          // La requête a été faite, mais aucune réponse n'a été reçue
          console.error('Pas de réponse reçue du serveur:', error.request);
      
          dispatch({
            type: 'FETCH_STATISTICS_FAILURE',
            payload: 'Pas de réponse reçue du serveur',
          });
      
          // Afficher une notification d'échec
          toast.error('Erreur lors de la lecture des statistics.');
        } else {
          // Une erreur s'est produite lors de la configuration de la requête
          console.error('Erreur lors de la configuration de la requête:', error.message);
      
          dispatch({
            type: 'FETCH_STATISTICS_FAILURE',
            payload: error.message,
          });
      
          // Afficher une notification d'échec
          toast.error('Erreur lors de la lecture des statistics.');
        }
      }
    };
  };

export const fetchReadingStats = (page = 1) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      if (!token) {
        throw new Error('Token non trouvé');
      }
      const response = await axios.get(`${APP_URL}statistiques/lecture?page=${page}`,{
        headers: {
          'Content-Type': 'application/ld+json',
          'Authorization': `Bearer ${token}`, 
        },
      });
      dispatch({
        type: 'FETCH_READINGS_SUCCESS',
        payload: response.data,
      });
   
    }  catch (error) {
      if (error.response) {
        // La requête a été faite, mais le serveur a répondu avec un code d'état différent de 2xx
        console.error('Réponse du serveur avec erreur:', error.response.data);
        console.error('Statut de la réponse:', error.response.status);
        console.error('En-têtes de la réponse:', error.response.headers);
    
        dispatch({
          type: 'FETCH_STATISTICS_FAILURE',
          payload: error.response.data,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la lecture des statistics');
      } else if (error.request) {
        // La requête a été faite, mais aucune réponse n'a été reçue
        console.error('Pas de réponse reçue du serveur:', error.request);
    
        dispatch({
          type: 'FETCH_STATISTICS_FAILURE',
          payload: 'Pas de réponse reçue du serveur',
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la lecture des statistics.');
      } else {
        // Une erreur s'est produite lors de la configuration de la requête
        console.error('Erreur lors de la configuration de la requête:', error.message);
    
        dispatch({
          type: 'FETCH_STATISTICS_FAILURE',
          payload: error.message,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la lecture des statistics.');
      }
    }
  };
};


export const fetchProgressionStats = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      if (!token) {
        throw new Error('Token non trouvé');
      }
      const response = await axios.get(`${APP_URL}statistiques/get-all-progression`,{
        headers: {
          'Content-Type': 'application/ld+json',
          'Authorization': `Bearer ${token}`, 
        },
      });
      
      dispatch({
        type: 'FECTH_PROGRESSION_SUCCESS',
        payload: response.data,
      });
   
    }  catch (error) {
      if (error.response) {
        // La requête a été faite, mais le serveur a répondu avec un code d'état différent de 2xx
        console.error('Réponse du serveur avec erreur:', error.response.data);
        console.error('Statut de la réponse:', error.response.status);
        console.error('En-têtes de la réponse:', error.response.headers);
    
        dispatch({
          type: 'FECTH_PROGRESSION_FAILURE',
          payload: error.response.data,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la lecture des statistics');
      } else if (error.request) {
        // La requête a été faite, mais aucune réponse n'a été reçue
        console.error('Pas de réponse reçue du serveur:', error.request);
    
        dispatch({
          type: 'FECTH_PROGRESSION_FAILURE',
          payload: 'Pas de réponse reçue du serveur',
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la lecture des statistics.');
      } else {
        // Une erreur s'est produite lors de la configuration de la requête
        console.error('Erreur lors de la configuration de la requête:', error.message);
    
        dispatch({
          type: 'FECTH_PROGRESSION_FAILURE',
          payload: error.message,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la lecture des statistics.');
      }
    }
  };
};

export const fetchStasBookByMonths = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      if (!token) {
          throw new Error('Token non trouvé');
        }
      const response = await axios.get(APP_URL+'statistiques/livre-lu-par-mois',{
          headers: {
              'Content-Type': 'application/ld+json',
              'Authorization': `Bearer ${token}`, // Assurez-vous que votre API utilise "Bearer" suivi du jeton
            },
      });
      dispatch({
        type: 'FETCH_STATISTICS_BOOK_READ_SUCCESS',
        payload: response.data,
      });
      console.log('Réponse de l\'API:', response.data);
    } catch (error) {
      if (error.response) {
        // La requête a été faite, mais le serveur a répondu avec un code d'état différent de 2xx
        console.error('Réponse du serveur avec erreur:', error.response.data);
        console.error('Statut de la réponse:', error.response.status);
        console.error('En-têtes de la réponse:', error.response.headers);
    
        dispatch({
          type: 'FETCH_STATISTICS_BOOK_READ_FAILURE',
          payload: error.response.data,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la lecture des statistics');
      } else if (error.request) {
        // La requête a été faite, mais aucune réponse n'a été reçue
        console.error('Pas de réponse reçue du serveur:', error.request);
    
        dispatch({
          type: 'FETCH_STATISTICS_BOOK_READ_FAILURE',
          payload: 'Pas de réponse reçue du serveur',
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la lecture des statistics.');
      } else {
        // Une erreur s'est produite lors de la configuration de la requête
        console.error('Erreur lors de la configuration de la requête:', error.message);
    
        dispatch({
          type: 'FETCH_STATISTICS_BOOK_READ_FAILURE',
          payload: error.message,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la lecture des statistics.');
      }
    }
  };
};
