import axios from "axios";
import { toast } from "react-toastify";
import { APP_URL } from "../URL";

export const loginUser = (user) => (dispatch)=> {
    dispatch ({
        type: 'LOGIN_USER',
        payload: user,
      });
    localStorage.setItem('user', JSON.stringify(user));
};

export  const logoutUser = ()=>({
    type: 'LOGOUT_USER',
});


export const fetchAllUsers = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      if (!token) {
          throw new Error('Token non trouvé');
        }
      const response = await axios.get(APP_URL+'users',{
          headers: {
              'Content-Type': 'application/ld+json',
              'Authorization': `Bearer ${token}`, // Assurez-vous que votre API utilise "Bearer" suivi du jeton
            },
      });
      
      dispatch({
        type: 'FETCH_USERS_SUCCESS',
        payload: response.data,
      });
      console.log('reponse du serveur', response.data);
    }  catch (error) {
      if (error.response) {
        // La requête a été faite, mais le serveur a répondu avec un code d'état différent de 2xx
        console.error('Réponse du serveur avec erreur:', error.response.data);
        console.error('Statut de la réponse:', error.response.status);
        console.error('En-têtes de la réponse:', error.response.headers);
    
        dispatch({
          type: 'FETCH_USERS_FAILURE',
          payload: error.response.data,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la création du livre.');
      } else if (error.request) {
        // La requête a été faite, mais aucune réponse n'a été reçue
        console.error('Pas de réponse reçue du serveur:', error.request);
    
        dispatch({
          type: 'FETCH_USERS_FAILURE',
          payload: 'Pas de réponse reçue du serveur',
        });
    
        console.log('Erreur lors de la lecture des utilisateur.');
      } else {
        console.error('Erreur lors de la configuration de la requête:', error.message);
    
        dispatch({
          type: 'FETCH_USERS_FAILURE',
          payload: error.message,
        });
    
        console.log('Erreur lors de la lecture des utilisateurs');
      }
    }
  };
};

export const deleteUser = (userId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trouvé');
      }
      const response = await axios.post(`${APP_URL}users/${userId}`, {}, {
        headers: {
          'Content-Type': 'application/ld+json',
          'Authorization': `Bearer ${token}`, // Assurez-vous d'avoir un token valide
        },
      });

      if (response.status === 200) {
        // Dispatchez une action de succès si la suppression est réussie
        dispatch({
          type: 'DELETE_USER_SUCCESS',
          payload: userId, // Passez l'ID de l'utilisateur supprimé en payload
        });

        toast.success('Utilisateur supprimé avec succès!');
      } else {
      
        throw new Error('La suppression de l\'utilisateur a échoué');
      }
    } catch (error) {

      dispatch({
        type: 'DELETE_USER_FAILURE',
        payload: error.message, 
      });

      toast.error('Erreur lors de la suppression de l\'utilisateur.');
    }
  };
};


export const restoreUser = (userId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trouvé');
      }
      const response = await axios.post(`${APP_URL}restore-user/${userId}`, {},  {
        headers: {
          'Content-Type': 'application/ld+json',
          'Authorization': `Bearer ${token}`,
        },
      });
       
      if(response.status === 200 ){
          dispatch({
            type: 'RESTORE_USER_SUCCESS',
            payload: userId, // Passez l'ID de l'utilisateur restauré en payload
          });
          toast.success('Utilisateur restore avec succès!');
      }else{
        throw new Error('La restoration de l\'utilisateur a échoué');
      }
   
    } catch (error) {
      // Dispatchez une action d'échec en cas d'erreur
      dispatch({
        type: 'RESTORE_USER_FAILURE',
        payload: error.message, // Passez le message d'erreur en payload si nécessaire
      });

      toast.error('Erreur lors de la restoration de l\'utilisateur.');
    }
  };
};