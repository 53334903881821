const initialState = {
    user : null,
    users : [],
    loading: "",
    error : null,
};

const userReducer = (state = initialState, action)=>{

    switch (action.type){
        case 'LOGIN_USER':
           // console.log('Reducer : Mise à jour de l\'utilisateur avec les données :', action.payload);   
            return { ...state, user: action.payload };
        case 'LOGOUT_USER':
            return { ...state, user: null };
            case "FETCH_USERS_SUCCESS":
                return {
                    ...state,
                    users: action.payload,
                    loading: false,
                    error: null,
                  };
            case "FETCH_USERS_FAILURE":
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                  };
                  case "DELETE_USER_FAILURE":
                    return{
                        ...state,
                        loading: false,
                        error: action.payload,
                    }; 
                    case "DELETE_USER_SUCCESS":
                       const updateUsers= Array.isArray(state.users) ? state.users.filter((user) => user.id !== action.payload) : state.users
                        return {
                            ...state,
                           users : updateUsers
                        }; 
                        case 'RESTORE_USER_SUCCESS':
                            return {
                              ...state,
                              users: Array.isArray(state.users) ? state.users.filter((user) => user.id !== action.payload) : state.users
                            };
                      
                          case 'RESTORE_USER_FAILURE':
                            // Gérez l'échec de la restauration si nécessaire
                            return state;
                
                    default:
                 return state;
    }

};
export default userReducer;