
import axios from 'axios';
import { toast } from 'react-toastify';
import { APP_URL } from '../URL';

export const deletechapter =(chapterId) =>{

    return async (dispatch) =>{
       
        try {
            const token = localStorage.getItem('token');
             if(!token){
                throw new Error('Token non trouvé');
             } 
             const response = axios.delete(`${APP_URL}delete-chapitre/${chapterId}`,{
                headers: {
                    'Content-Type': 'application/ld+json',
                    'Authorization': `Bearer ${token}`,
                  }
             });
             dispatch({
                type: 'DELETE_CHAPITRE_SUCESS',
                payload: chapterId
             })
             toast.success("chapitre supprimé avec succès")
        } catch (error) {
            if (error.response) {
              console.error('Réponse du serveur avec erreur:', error.response.data);
              console.error('Statut de la réponse:', error.response.status);
              console.error('En-têtes de la réponse:', error.response.headers);
          
              dispatch({
                type: 'DELETE_CHAPITRE_FAILURE',
                payload: error.message,
              });
          
              // Afficher une notification d'échec
              toast.error('Erreur lors de la suppresion du chapitre.');
            } else if (error.request) {
              // La requête a été faite, mais aucune réponse n'a été reçue
              console.error('Pas de réponse reçue du serveur:', error.request);
          
              dispatch({
                type: 'DELETE_CHAPITRE_FAILURE',
                payload: 'Pas de réponse reçue du serveur',
              });
          
              // Afficher une notification d'échec
              toast.error('Erreur lors de la suppression du chapitre.');
            } else {
              // Une erreur s'est produite lors de la configuration de la requête
              console.error('Erreur lors de la configuration de la requête:', error.message);
          
              dispatch({
                type: 'DELETE_CHAPITRE_SUCCESS',
                payload: error.message,
              });
          
              // Afficher une notification d'échec
              toast.error('Erreur lors de la suppression du chapitre.');
            }
          }
    }
}


export const updateChapter = (chapterId, updatedChapter) => {
  
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé');
        }
        const response = await axios.put(
          `${APP_URL}update-chapter/${chapterId}`,
          updatedChapter,
          {
            headers: {
              'Content-Type': 'application/ld+json',
              'Authorization': `Bearer ${token}`,
            },
          }
        )
        dispatch({
          type: 'UPDATE_CHAPITRE_SUCCESS',
          payload: response.data,
        });
        toast.success('Chaptitre  modifié avec succès!');
      } catch (error) {
        if (error.response) {
          console.error('Réponse du serveur avec erreur:', error.response.data);
          console.error('Statut de la réponse:', error.response.status);
          console.error('En-têtes de la réponse:', error.response.headers);
      
          dispatch({
            type: 'UPDATE_CHAPITRE_FAILURE',
            payload: error.response.data,
          });
      
          toast.error('Erreur lors de la modification du chapitre.');
        } else if (error.request) {
          console.error('Pas de réponse reçue du serveur:', error.request);
      
          dispatch({
            type: 'UPDATE_CHAPITRE_FAILURE',
            payload: 'Pas de réponse reçue du serveur',
          });
   
          toast.error('Erreur lors de la création du livre.');
        } else {
         
          console.error('Erreur lors de la configuration de la requête:', error.message);
      
          dispatch({
            type: 'UPDATE_CHAPITRE_FAILURE',
            payload: error.message,
          });
          toast.error('Erreur lors de la modfication du chapitre.');
        }
      }
    };
  };
  