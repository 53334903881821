import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteBook, fetchBooks } from "../actions/BooksActions";
import ReactPaginate from "react-paginate";
import "./Pagignate.css";
import { ConfirmationModal } from './ConfirmationModal';
import EditConfirmationModal from "./EditConfirmationModal";
import { fetchCategories } from "../actions/CategoryActions";
import EditCategoriesModal from "./EditCategoriesModal";
import { useRecoilState } from "recoil";
import { bookIdState, loadingBooksListState, modalBookUpdateState, modalCategoryState, modalChapterUpdateState, modalCoverUpdateState, modalDataBookUpdateState, modalDataCategoeyState, modalDataChapterUpdateState, modalDataCoverUpdateState, modalDataDeleteBook, modaldeleteBook, subViewState } from "../actions/Atom";
import { Spin } from "antd";
import { Button, Dropdown } from 'antd';
import ShowCategory from "./modals/ShowCategory";
import ShowBookUpdate from "./modals/ShowBookupdate";
import ShowCoverUpdate from "./modals/ShowCoverUpdate";
import ShowChapterList from "./modals/ShowChapterList";
import BookForm from "./BookForm";
import axios from "axios"; // Importez axios
import { jwtDecode } from "jwt-decode";
import { APP_URL } from "../URL";

const BookList = ({  books, categories, loading, error, fetchBooks,deleteBook }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [showModalDelete, setShowModalDelete] = useRecoilState(modaldeleteBook);
  const [bookToDelete, setBookToDelete] = useRecoilState(modalDataDeleteBook);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState(false);
  const [bookId, setBookId] = useRecoilState(bookIdState);
  const [loadingList,setLoadingList] = useRecoilState(loadingBooksListState);
  const [isCreateBookVisible, setIsCreateBookVisible] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true); // État pour vérifier si le token est valide

  // pour show category
  const [isModalOpenCategory, setIsModalOpenCategory] = useRecoilState(modalCategoryState);
  const [modalData, setModalData] = useRecoilState(modalDataCategoeyState);

  // pour show modal book update
  const [isModalOpenBook, setIsModalOpenBook] = useRecoilState(modalBookUpdateState);
  const [modalDataBook, setModalDataBook] = useRecoilState(modalDataBookUpdateState);

  // pour show modal cover book update
  const [isModalOpenCover, setIsModalOpenCover] = useRecoilState(modalCoverUpdateState);
  const [modalDataCover, setModalDataCover] = useRecoilState(modalDataCoverUpdateState);

  // pour show modal look chapter
  const [isModalOpenChapter, setIsModalOpenChapter] = useRecoilState(modalChapterUpdateState);
  const [modalDataChapter, setModalDataChapter] = useRecoilState(modalDataChapterUpdateState);

  const [subView, setSubView] = useRecoilState(subViewState);

  useEffect(() => {
    fetchBooks();
    checkTokenValidity(); // Vérifier la validité du token lors du chargement initial du composant
  }, [fetchBooks,modalDataCover]);

  // Fonction pour vérifier la validité du token
  const checkTokenValidity = async () => {
    const token = localStorage.getItem("token");
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    if (token && tokenExpiration) {
      const currentTime = Date.now();
      if (currentTime >= parseInt(tokenExpiration)) {
        setIsTokenValid(false);
      }
    }
  };

  // Fonction pour rafraîchir le token
  const refreshToken = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${APP_URL}/token/refresh`, { token });
      const newToken = response.data.token;
      const decodedToken = jwtDecode(newToken);
      const expirationTime = decodedToken.exp * 1000;
      localStorage.setItem("token", newToken);
      localStorage.setItem("tokenExpiration", expirationTime.toString());
      setIsTokenValid(true);
    } catch (error) {
      // En cas d'erreur, déconnectez l'utilisateur et redirigez-le vers le composant Login
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      navigate("/login");
    }
  };

  // Vérifiez la validité du token à intervalles réguliers et rafraîchissez-le si nécessaire
  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenValidity();
      if (!isTokenValid) {
        refreshToken();
      }
    }, 60000); // Vérifiez toutes les minutes
    return () => clearInterval(interval);
  }, [isTokenValid]);

  const handleShowModal = () => setShowModalDelete(true);

  const handleShowEditModal = () => setShowEditModal(true);
  const handleHideEditModal = () => setShowEditModal(false);

  const handleShowEditCategoriesModal = () => setShowEditCategoriesModal(true);
  const handleHideEditCategoriesModal = () => setShowEditCategoriesModal(false);

  const handleItemClick = () => {
    setIsCreateBookVisible(true);
  };
  const handledeleteBook = (book) => {
    setBookToDelete(book.id);
    setShowModalDelete(true);
  };

  const handleHideDeleteModal = () => {
    setBookToDelete(null);
    setShowModalDelete(false);
  };

  const handleDeleteBook = (book) => {
    if (book) {
      deleteBook(bookToDelete.id);
      handleHideDeleteModal();
    }
  };

  const handleEditBook = () => {
    handleHideEditModal();
  };
  const handleEditClick = (book) => {
    setSelectedBook(book);
    setBookId(book.id);
    setShowEditModal(true);
  };
  const handleEditCategories = () => {
    handleHideEditCategoriesModal();
  };

  if (loading) {
    return <p><Spin/></p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  if (isCreateBookVisible) {
    return <BookForm />;
  }

  const booksPerPage = 10;
  const offset = Array.isArray(books) ? currentPage * booksPerPage : 0;
  const currentBooks = Array.isArray(books) ? books.slice(offset, offset + booksPerPage) : [];

  const pageCount = Array.isArray(books) ? Math.ceil(books.length / booksPerPage) : 0;

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };


  return (
    
    <div>

      <div style={{ display: 'flex', alignItems: 'center' , marginBottom: '30px'}}>
      <h2 style={{ marginRight: '10px' }}>Liste des Livres</h2>
          <button onClick={handleItemClick}
            style={{
              backgroundColor: "green",
              color: "white",
              padding: "10px",
              border: "none",
              cursor: "pointer"
            }}
          >
          Ajouter un livre {loadingList? <><Spin /></>:<></>}
          </button>
     </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Couvertures</th>
            <th scope="col">Titre</th>
            <th scope="col">Genre</th>
            <th scope="col">Éditeur</th>
            <th scope="col">Langue</th>
            <th scope="col">Nombre de pages</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
       
          {currentBooks.map((book) => (
            <tr key={book.id}>
              <td>{book.id}</td>
              <td>
                <img src={`${book.couverture}`} style={{height:'100px', width:'100px'}} />
              </td>
              <td>{book.titre}</td>
              <td>{book.genre}</td>
              <td>{book.editeur}</td>
              <td>{book.langue}</td>
              <td>{book.nb_page}</td>
              <td>
            
    <Dropdown
      menu={{
        items:[
          {
            key: '1',
            label: (
              <a onClick={() => {setIsModalOpenChapter(true); setModalDataChapter(book); setSubView('add_chap')}}  style={{textDecoration:'none', color:'green'}}  rel="noopener noreferrer" href="javascript:void(0)">
                Les chapitres du livre
              </a>
            ),
          },
          {
            key: '2',
            label: (
              <a  style={{textDecoration:'none'}} onClick={() => handleEditClick(book)} rel="noopener noreferrer" href="javascript:void(0)">
                Modifier les infos du livre
              </a>
            ),
          },
          {
            key: '3',
            label: (
              <a onClick={() => {setIsModalOpenCover(true); setModalDataCover(book)}}  style={{textDecoration:'none'}}  rel="noopener noreferrer" href="javascript:void(0)">
                Modifier la photo de couverture
              </a>
            ),
          },
          {
            key: '4',
            label: (
              <a onClick={() => { setIsModalOpenBook(true); setModalDataBook(book) } } style={{textDecoration:'none',color:'orange'}}  rel="noopener noreferrer" href="javascript:void(0)">
                Modifier le fichier
              </a>
            ),
          },
          {
            key: '5',
            label: (
              <a  onClick={() => { setIsModalOpenCategory(true); setModalData(book) }} style={{textDecoration:'none'}} rel="noopener noreferrer" href="javascript:void(0)">
                Voir les categories
              </a>
            ),
          },
          {
            key: '6',
            label: (
              <a  onClick={() => {
                handledeleteBook(book.id);
                setBookToDelete(book);
              }} style={{textDecoration:'none', color:'red'}} rel="noopener noreferrer" href="javascript:void(0)">
                supprimé ce livre
              </a>
            ),
          },
          
        
        ],
      }}
      placement="bottomLeft"
      arrow
    >  
      <Button>Voir les actions</Button>
    </Dropdown>
              </td>
             
            </tr>
               
          ))}
        </tbody>
      </table>
        
      <ReactPaginate
        previousLabel={'Précédent'}
        nextLabel={'Suivant'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        previousClassName={'pagination-previous'} 
        nextClassName={'pagination-next'} 
        breakClassName={'pagination-break'} 
        pageClassName={'pagination-item'} 
        activeClassName={'active'}
      />
                      <ConfirmationModal
                  show={showModalDelete}
                  onHide={handleHideDeleteModal}
                  onConfirm={handleDeleteBook}
                  book={bookToDelete}
                />
                <EditConfirmationModal
                  show={showEditModal}
                  onHide={handleHideEditModal}
                  onConfirm={handleEditBook}
                  bookData={selectedBook}
                />
                  <EditCategoriesModal
                  show={showEditCategoriesModal}
                  onHide={handleHideEditCategoriesModal}
                  onConfirm={handleEditCategories}
                  categories={categories}
                  selectedCategories={selectedBook ? selectedBook.categories : []}
                />

    <ShowCategory />
    <ShowBookUpdate />
    <ShowCoverUpdate />
    <ShowChapterList />
          
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    books: state.books.books,
    loading: state.books.loading,
    error: state.books.error,
    categories: state.categories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchBooks: () => dispatch(fetchBooks()),
  fetchCategories: () => dispatch(fetchCategories()),
  deleteBook: (userId) => dispatch(deleteBook(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookList);
