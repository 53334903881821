const initialState = {
    progressions: [],
    loading: false,
    error: null,
    currentPageNumber: 1,
    totalPages: 0,
  };
  
  const progressionStatsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_PROGRESSION_STATS_REQUEST':
        return {
          ...state,
          loading: true,
          error: null,
        };
      case 'FETCH_PROGRESSION_STATS_SUCCESS':
        return {
          ...state,
          loading: false,
          progressions: action.payload.datas,
          currentPageNumber: action.payload.currentPageNumber,
          totalPages: action.payload.totalPages,
        };
      case 'FETCH_PROGRESSION_STATS_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default progressionStatsReducer;
  