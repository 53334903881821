import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { modalBookUpdateState, modalDataBookUpdateState } from "../../actions/Atom";
import { Modal, Spin, Upload, message, notification } from "antd";
import { APP_URL } from "../../URL";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";

const getBase64 = (file) => 
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function ShowBookUpdate(){
    const token = localStorage.getItem('token');
    const [titreLivre, setTitreLivre] = useState('');
    const [loading, setLoading] = useState(true);

    const [modalDataBook, setModalDataBook] = useRecoilState(modalDataBookUpdateState);
    const [modalBook, setModalBook] = useRecoilState(modalBookUpdateState);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([modalDataBook]);
    const [selected, setSelected] = useState(false);
    const [loadingChange, setLoadingChange] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
  
    const handleCancelPre = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const showModal = () => {
        setModalBook(true);
      };
      const handleOk = () => {
        setModalBook(false);
      };
      const handleCancel = () => {
        setModalBook(false);
      };

  const handleChange = ({ fileList: newFileList }) => {setFileList(newFileList); setSelected(true);  }
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

      useEffect(() => {

      },[selected]);

    return (
        <>
          <Modal title={`Modifier le fichier:  "${modalDataBook.titre}"`} open={modalBook} onOk={handleOk} onCancel={handleCancel}>
       
            <center>
                <img src={`https://awoula.consolidis.com/app/public/images/books/couverture/${modalDataBook.couverture}`} style={{height:'100px', width:'100px', borderRadius:'100%'}} />
            </center>
       <hr />

       {loadingChange ? <>

        <br /><br />

        <center>
            <Spin size="large" />
            
        </center>
        
        <br />

       </>:<>
       <h6><b  style={{color:'red'}}>{errorMsg}</b></h6>
       <Upload
            customRequest={(file) => {
              const token = localStorage.getItem('token')
              setLoadingChange(true)
              setErrorMsg('');
              if (!token) {
                throw new Error('Token non trouvé');
              }
              return axios.post(`${APP_URL}add-file-book/${modalDataBook.id}`, {
                file,
              }, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}`,
                }
              }).then((response) => {
                console.log(response.data)
                message.success('Le fichier a bien été modifié');
                setModalBook(false); // on ferme le modal
                setLoadingChange(false);
                // handleItemClick(response.data.book_id, response.data.titre);
              }).catch((error) => {
                if (error.response) {
                  console.log(error.response.data);
                  setErrorMsg('Erreur: ' + error.response.data);
                } else {
                  console.log(error.message);
                  setErrorMsg('Erreur: ' + error.message);
                }
                setLoadingChange(false);
              });
            }}

            listType="picture-card"
            fileList={[modalDataBook]}
            onPreview={handlePreview}
            onChange={handleChange}
            name="file"
          >
            {fileList.length >= 2 ? null : uploadButton}
          </Upload>

          <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancelPre}>
            <img
              alt="example"
              style={{
                width: '100%',
              }}
              src={previewImage}
            />
          </Modal>
       
       </>}

       
         </Modal>
        </>
    );
}