import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Spin, Table } from 'antd';
import { fetchProgressionStats } from '../actions/StatisticsActions';
import './Pagignate.css';  // Importez le fichier CSS ici

const ProgressionList = ({ progressions, loading, error, currentPageNumber, totalPages, fetchProgressionStats }) => {
    useEffect(() => {
      fetchProgressionStats(currentPageNumber);
    }, [fetchProgressionStats, currentPageNumber]);
  
    const handlePageClick = (data) => {
      fetchProgressionStats(data.selected + 1);
    };
  
    const columns = [
      { title: 'ID', dataIndex: 'id', key: 'id' },
      { title: 'User', dataIndex: 'user', key: 'user' },
      { title: 'Livre', dataIndex: 'livre', key: 'livre' },
      { title: 'Page Actuelle', dataIndex: 'page_actuelle', key: 'page_actuelle' },
      { title: 'Nombre de Sessions', dataIndex: 'nb_sessions', key: 'nb_sessions' },
      { title: 'Progression', dataIndex: 'progression', key: 'progression' },
    ];
  
    if (loading) {
      return <Spin />;
    }
  
    if (error) {
      return <p>Erreur: {error}</p>;
    }
  
    return (
      <div>
        <h2>Progression des Utilisateurs</h2>
        <Table 
          dataSource={progressions} 
          columns={columns} 
          rowKey="id" 
          pagination={false} 
        />
        <ReactPaginate
          previousLabel={'Précédent'}
          nextLabel={'Suivant'}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </div>
    );
  };
  
  const mapStateToProps = (state) => ({
    progressions: state.progressions.progressions,
    loading: state.progressions.loading,
    error: state.progressions.error,
    currentPageNumber: state.progressions.currentPageNumber,
    totalPages: state.progressions.totalPages,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    fetchProgressionStats: (page) => dispatch(fetchProgressionStats(page)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProgressionList);
