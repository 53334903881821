import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createBook } from '../actions/BooksActions';
import { fetchCategories } from '../actions/CategoryActions';
import axios from 'axios';
import { APP_URL } from '../URL';
import { useNavigate } from 'react-router-dom';
import Etape2Livre from './Etape2ivre';
import { useRecoilState } from 'recoil';
import { bookIdState } from '../actions/Atom';
import { DatePicker, Spin } from 'antd';
import BookList from './BookList';
import dayjs from 'dayjs'; // Utiliser uniquement Day.js

const BookForm = ({ categories, fetchCategories }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [bookId, setBookId] = useRecoilState(bookIdState);
  const [isListBookVisible, setIsListBookVisible] = useState(false);

  let obj = new FormData();
  const navigate = useNavigate();
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleItemClick = (bookId, bookTitle) => {
    setCurrentStep(2);
  };
  const handleItemListClick = () => {
    setIsListBookVisible(true);
  };

  const [bookData, setBookData] = useState({
    titre: '',
    description: '',
    genre: '',
    annee_parution: '',
    isbm: '',
    editeur: '',
    resume: '',
    langue: '',
    nb_page: 0,
    age_min: 0,
    categories: [],
    type: 'eBook',
    file: '',
  });

  const [selectedDate, setSelectedDate] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBookData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    obj.append('name', 'value');
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log('Selected File:', file);
    setSelectedFile(file);
  };
  const handleClearFile = (e) => {
    setSelectedFile(null);
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    const selectedCategories = bookData.categories.includes(Number(value))
      ? bookData.categories.filter((cat) => cat !== Number(value))
      : [...bookData.categories, Number(value)];

    setBookData((prevData) => ({
      ...prevData,
      categories: selectedCategories,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const bookFormData = {
      ...bookData,
      annee_parution: selectedDate, 
    };
    axios
      .post(APP_URL + 'livres', bookFormData, {
        headers: {
          'Content-Type': 'application/ld+json',
          // 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (res.data && typeof res.data === 'object' && res.data.book.id) {
          console.log(res.data);
          setBookId(res.data.book.id);
          handleItemClick(res.data.book.id, bookData.titre);
        } else {
          console.error('Invalid response format:', res.data);
        }
      })
      .catch((error) => console.log(error));
  };
  if (isListBookVisible) {
    return <BookList />;
  }

  if (currentStep === 1) {
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
          <h2 style={{ marginRight: '10px' }}>Créer un nouveau livre</h2>
          <button
            onClick={handleItemListClick}
            style={{
              backgroundColor: 'green',
              color: 'white',
              padding: '10px',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Liste des Livres<Spin />
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="titre" className="form-label">
                Titre du livre
                <span style={{ color: 'red', paddingLeft: '5px', fontSize: '20px' }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="titre"
                name="titre"
                value={bookData.titre}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="nb_page" className="form-label">
                Age Minimun requis
              </label>
              <input
                type="number"
                className="form-control"
                id="age_min"
                name="age_min"
                min="0"
                value={bookData.age_min}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="genre" className="form-label">
                Genre du livre
                <span style={{ color: 'red', paddingLeft: '5px', fontSize: '20px' }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="genre"
                name="genre"
                value={bookData.genre}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="annee_parution" className="form-label">
                Année de parution
                <span style={{ color: 'red', paddingLeft: '5px', fontSize: '20px' }}>*</span>
              </label>
              <DatePicker
                  className="form-control"
                  id="annee_parution"
                  name="annee_parution"
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}   
                  required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="isbm" className="form-label">
                ISBN
                <span style={{ color: 'red', paddingLeft: '5px', fontSize: '20px' }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="isbm"
                name="isbm"
                value={bookData.isbm}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="editeur" className="form-label">
                Éditeur du livre
                <span style={{ color: 'red', paddingLeft: '5px', fontSize: '20px' }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="editeur"
                name="editeur"
                value={bookData.editeur}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="langue" className="form-label">
                Langue du livre
                <span style={{ color: 'red', paddingLeft: '5px', fontSize: '20px' }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="langue"
                name="langue"
                value={bookData.langue}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="nb_page" className="form-label">
                Nombre de pages
              </label>
              <input
                type="number"
                className="form-control"
                id="nb_page"
                name="nb_page"
                min="0"
                value={bookData.nb_page}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="resume" className="form-label">
                Résumé du livre
                <span style={{ color: 'red', paddingLeft: '5px', fontSize: '20px' }}>*</span>
              </label>
              <textarea
                className="form-control"
                id="resume"
                name="resume"
                value={bookData.resume}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="description" className="form-label">
                Description du livre
                <span style={{ color: 'red', paddingLeft: '5px', fontSize: '20px' }}>*</span>
              </label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={bookData.description}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="categories" className="form-label">
                Catégories
              </label>
              <div>
                {categories &&
                  categories.map((category) => (
                    <div key={category.id} className="form-check">
                      <input
                        type="checkbox"
                        id={`category_${category.id}`}
                        name="categories"
                        value={category.id}
                        checked={bookData.categories.includes(category.id)}
                        onChange={handleCategoryChange}
                        className="form-check-input"
                      />
                      <label htmlFor={`category_${category.id}`} className="form-check-label">
                        {category.nom}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="type" className="form-label">
                Type de livre
              </label>

              <select className="form-select" id="type" name="type" value={bookData.type} onChange={handleChange}>
                <option value="ebook">Ebook</option>
                <option value="AudioBook">AudioBook</option>
              </select>
            </div>
          </div>
          <br />
          <button type="submit" className="btn btn-primary">
            Enregistrons et Passons a l'etape suivant
          </button>
        </form>
      </div>
    );
  } else if (currentStep === 2) {
    return <Etape2Livre />;
  }
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories.categories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createBook: (bookData) => dispatch(createBook(bookData)),
  fetchCategories: () => dispatch(fetchCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookForm);

