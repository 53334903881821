import { Button, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { APP_URL } from "../../../URL";
import { useRecoilState } from "recoil";
import { chapIdState, subViewState } from "../../../actions/Atom";

export default function AddChap({ livre }) {
  let obj = new FormData();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [subView, setSubView] = useRecoilState(subViewState);
  const [chapId, setChapId] = useRecoilState(chapIdState);

  const [bookData, setBookData] = useState({
    titre: '',
    resume: '',
    type: 'audiobook'
  });

  const config = {
    headers: {
      'Content-Type': 'application/ld+json',
      'Authorization': `Bearer ${token}`,
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios.post(APP_URL + 'chapitres/' + livre.id, bookData, config)
      .then((response) => {
        setLoading(false);
        message.success('Chapitre créé avec succès');
        setSubView('uploadFile');
        setChapId(response.data.chapitre_id);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    console.log('bookData', bookData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBookData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    obj.append('name', 'value');
  };

  useEffect(() => {
    // Effect code here
  }, []);

  return (
    <>
    <div>
  <h5>Ajouter un nouveau chapitre</h5>
  <br />
  <div>
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6">
          <label>Titre</label>
          <input required className="form-control" name={"titre"} value={bookData.titre} onChange={handleChange} />
        </div>
        <div className="col-lg-6">
          <label>Type de fichier</label>
          <select name="type" className="form-control">
            <option value={'audioBook'}>AudioBook</option>
            <option value={'ebook'}>eBook</option>
          </select>
        </div>
      </div>
      <br />
      <label>Biographie</label>
      <textarea required className="form-control" name="resume" onChange={handleChange}>{bookData.resume}</textarea>
      <br />
      <Button htmlType="submit" type="submit" loading={loading} className="btn btn-primary form-control">Enregistrer</Button>
      <br />
    </form>
  </div>
</div>
    </>
  );
}
