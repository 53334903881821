import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { Spin, Modal, Dropdown, Button } from "antd";
import { DeleteOutlined, DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { chapIdState, modalChapterUpdateState, modalDataDeleteChapter, modalDataUpdateChapter, ModalDeleteChap, modalFileChapterUpdateState, ModalUpdateChap, subViewState } from "../../../actions/Atom";
import ModalDeleteChapter from "./ModalDeleteChapter";
import axios from "axios";
import { APP_URL } from "../../../URL";
import { connect } from "react-redux";
import { deletechapter } from "../../../actions/ChapitreActions";
import EditChapterModal from "./EditChapterModal";
import EditUploadFile from "./EditUploadFile";

const ListChap = ({ livre, deletechapter }) => {
  const [subView, setSubView] = useRecoilState(subViewState);
  const [isModalOpenChapter, setIsModalOpenChapter] = useRecoilState(modalChapterUpdateState);
  const [showModalDeleteChapter, setShowModalDeleteChapter] = useRecoilState(ModalDeleteChap);
  const [chapterToDelete, setChapterToDelete] = useRecoilState(modalDataDeleteChapter);
  const [showModalUpdateChapter, setShowModalUpdateChapter] = useRecoilState(ModalUpdateChap);
  const [showModalUpdateFile, setShowModalUpdateFile] = useRecoilState(modalFileChapterUpdateState)
  const [chapterToUpdate, setChapterToUpdate] = useRecoilState(modalDataUpdateChapter);
  const [chapterId, setChapterId] = useRecoilState(chapIdState);
  const [loading, setLoading] = useState(true);
  const [chapitres, setChapitres] = useState([]);
  const token = localStorage.getItem('token');
  const [showEditUploadFile, setShowEditUploadFile] = useState(false); // État pour afficher le modal EditUploadFile

  const handleShowDeleteModal = () => setShowModalDeleteChapter(true);

  const handleShowUpdateModal = () => setShowModalUpdateChapter(true);

  const handledeleteChapter = (chapitres) => {
    setChapterId(chapitres.id);
    handleShowDeleteModal();
  };

  const handleupdateChapter = (chapterId) => {
    const chapterToUpdate = chapitres.find(item => item.id === chapterId);
    setChapterToUpdate(chapterToUpdate);
    setShowModalUpdateChapter(true);
    refreshChapterList();
  };

  const handleFileupdateChapter = (chapterId) => {
    const chapterToUpdate = chapitres.find(item => item.id === chapterId);
    setChapterToUpdate(chapterToUpdate);
    setShowEditUploadFile(true); // Afficher le modal EditUploadFile lorsque cette fonction est appelée
  }

  const handleHideDeleteModal = () => {
    setChapterToDelete(null);
    setShowModalDeleteChapter(false);
  };

  const handleHideupdateModal = () => {
    setChapterToUpdate(null);
    setShowModalUpdateChapter(false);
  };

  const handleDeleteChapter = async () => {
    if (chapterId) {
      await deletechapter(chapterId);
      handleHideDeleteModal();
      refreshChapterList(); // Rafraîchir la liste des chapitres après la suppression
    }
  };

  const fetchChapitres = async () => {
    try {
      const response = await axios.get(APP_URL + 'chapitres/' + livre.id, {
        headers: {
          'Content-Type': 'application/ld+json',
          'Authorization': `Bearer ${token}`,
        },
      });
      setChapitres(response.data.chapitres);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const refreshChapterList = () => {
    fetchChapitres(); // Rafraîchir la liste des chapitres
  };

  useEffect(() => {
    fetchChapitres();
  }, [isModalOpenChapter, livre.id]);

  const handleHideEditUploadFileModal = () => {
    setShowEditUploadFile(false); // Masquer le modal EditUploadFile
  };

  return (
    <>
      <h5>Liste des chapitres</h5>
      <br />
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {chapitres.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Titre</th>
                  <th>Description</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {chapitres.map((chapitre) => (
                  <tr key={chapitre.id}>
                    <td>{chapitre.id}</td>
                    <td>{chapitre.titre}</td>
                    <td>{chapitre.resume}</td>
                    <td>
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: '1',
                              label: (
                                <a
                                  onClick={() => handledeleteChapter(chapitre)}
                                  style={{ textDecoration: 'none', color: 'red' }}
                                  rel="noopener noreferrer"
                                  href="javascript:void(0)"
                                >
                                  Supprimer le chapitre
                                </a>
                              ),
                            },
                            {
                              key: '2',
                              label: (
                                <a
                                  style={{ textDecoration: 'none' }}
                                  onClick={() => handleupdateChapter(chapitre.id)}
                                  rel="noopener noreferrer"
                                  href="javascript:void(0)"
                                >
                                  Modifier le chapitre
                                </a>
                              ),
                            },
                            {
                              key: '3',
                              label: (
                                <a
                                  style={{ textDecoration: 'none', color: 'orange' }}
                                  onClick={() => handleFileupdateChapter(chapitre.id)}
                                  rel="noopener noreferrer"
                                  href="javascript:void(0)"
                                >
                                  Modifier le fichier du chapitre
                                </a>
                              ),
                            },
                          ],
                        }}
                        placement="bottomLeft"
                        arrow
                      >
                        <Button>Voir les actions</Button>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <DownloadOutlined style={{ fontSize: '3em', color: 'gray' }} />
              <h5>
                <span style={{ color: 'gray' }}>Aucun chapitre pour le moment</span>
              </h5>
            </div>
          )}
        </>
      )}
      <ModalDeleteChapter
        open={showModalDeleteChapter}
        onCancel={handleHideDeleteModal}
        onConfirm={handleDeleteChapter}
        chapter={chapterToDelete}
      />
      <EditChapterModal
        visible={showModalUpdateChapter}
        onCancel={handleHideupdateModal}
        chapter={chapterToUpdate}
        chapterId={chapterToUpdate ? chapterToUpdate.id : null}
        refreshChapterList={refreshChapterList}
      />
      {showEditUploadFile && (
        <Modal
          title="Modifier le fichier du chapitre"
          visible={showEditUploadFile}
          onCancel={handleHideEditUploadFileModal}
          footer={null}
        >
          <EditUploadFile
            open={showModalUpdateFile}
            chapId={chapterToUpdate ? chapterToUpdate.id : null}
          />
        </Modal>
      )}
    </>
  );  
}

const mapDispatchToProps = (dispatch) => ({
  deletechapter: (chapterId) => dispatch(deletechapter(chapterId)),
});

export default connect(null, mapDispatchToProps)(ListChap);
