import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { deleteCategory, fetchCategories } from '../actions/CategoryActions';
import ReactPaginate from "react-paginate";
import { Button, Dropdown, Menu, Spin } from 'antd';
import { categoryIdState, modaldeleteCategory, modalCategoryUpdateState, modalDataCategoryUpdateState } from '../actions/Atom';
import { useRecoilState } from 'recoil';
import DeleteCategoryModal from './DeleteCategoryModal';
import EditCategoryModal from './EditCategoryModal';

const CategoryList = ({ categories, loading, error, fetchCategories, deleteCategory }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [showModalDelete, setShowModalDelete] = useRecoilState(modaldeleteCategory);
  const [showModalUpdate, setShowModalUpdate] = useRecoilState(modalCategoryUpdateState);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [categoryToUpdate, setCategoryToUpdate] = useRecoilState(modalDataCategoryUpdateState);

  const handleEditCategory = (categoryId) => {
    const categoryToUpdate = categories.find(item => item.id === categoryId);
    setCategoryToUpdate(categoryToUpdate);
    setShowModalUpdate(true);
  };

  const refreshCategoryList = () => {
    fetchCategories();
  };

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleDeleteCategory = (category) => {
    setCategoryToDelete(category);
    setShowModalDelete(true);
  };

  const handleHideDeleteModal = () => {
    setShowModalDelete(false);
  };

  const handleHideUpdateModal = () => {
    setShowModalUpdate(false);
  };

  const handleConfirmDelete = async () => {
    if (categoryToDelete) {
      await deleteCategory(categoryToDelete.id);
      setShowModalDelete(false);
      refreshCategoryList();
    }
  };

  const catPerPage = 10;
  const offset = currentPage * catPerPage;
  const currentCat = categories ? categories.slice(offset, offset + catPerPage) : [];
  const pageCount = categories ? Math.ceil(categories.length / catPerPage) : 0;

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  if (error) {
    return <p>Erreur: {error}</p>;
  }

  if (loading) {
    return <Spin />;
  }

  return (
    <div>
      <h2>Liste des catégories</h2>
      {loading && <Spin />}
      {!loading && categories && (
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nom</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentCat.map((category) => (
              <tr key={category.id}>
                <td>{category.id}</td>
                <td>{category.nom}</td>
                <td>{category.description}</td>
                <td>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="1">
                          <a
                            onClick={() => handleDeleteCategory(category)}
                            style={{ textDecoration: 'none', color: 'red' }}
                            href="#"
                          >
                            Supprimer cette catégorie
                          </a>
                        </Menu.Item>
                        <Menu.Item key="2">
                          <a
                            onClick={() => handleEditCategory(category.id)}
                            style={{ textDecoration: 'none' }}
                            href="#"
                          >
                            Modifier cette catégorie
                          </a>
                        </Menu.Item>
                      </Menu>
                    }
                    placement="bottomLeft"
                  >
                    <Button>Voir les actions</Button>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ReactPaginate
        previousLabel={'Précédent'}
        nextLabel={'Suivant'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        previousClassName={'pagination-previous'}
        nextClassName={'pagination-next'}
        breakClassName={'pagination-break'}
        pageClassName={'pagination-item'}
        activeClassName={'active'}
      />

      <DeleteCategoryModal
        show={showModalDelete}
        onHide={handleHideDeleteModal}
        onConfirm={handleConfirmDelete}
        category={categoryToDelete}
      />

      <EditCategoryModal
        show={showModalUpdate}
        onHide={handleHideUpdateModal}
        category={categoryToUpdate}
        categoryId={categoryToUpdate ? categoryToUpdate.id : null}
        refreshCategoryList={refreshCategoryList}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  loading: state.categories.loading,
  error: state.categories.error,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCategories: () => dispatch(fetchCategories()),
  deleteCategory: (categoryId) => dispatch(deleteCategory(categoryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
