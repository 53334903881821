import { useState } from "react";
import { useRecoilState } from "recoil";
import { chapIdState, subViewState } from "../../../actions/Atom";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { APP_URL } from "../../../URL";
import axios from "axios";

const getBase64 = (file) => 
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


export default function UploadFile({livre}){

    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');
    const [subView, setSubView] = useRecoilState(subViewState);
    const [chapId, setChapId] = useRecoilState(chapIdState);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);

     const [selected, setSelected] = useState(false);



    // const showModal = () => {
    //     setIsModalOpenCover(true);
    //   };
      const handleOk = () => {
        // setIsModalOpenCover(false);
        setFileList([]);
      };
    //   const handleCancelAp = () => {
    //     setIsModalOpenCover(false);
    //     setFileList([]);
    //   };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList: newFileList }) => { setFileList(newFileList); setSelected(true); }
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );


    return (
        <>
            <h5>Ajouter un fichier a votre chapitre</h5>
            <center>
            <Upload
            customRequest={(file) => {
              console.log(file)
              const token = localStorage.getItem('token')
             
              if (!token) {
                throw new Error('Token non trouvé');
              }
              return axios.post(`${APP_URL}upload-file-chap/${chapId}`, {
                file,
              }, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}`,
                }
              }).then((response) => {
                console.log(response.data)
                setFileList(response.data);
                
                setSubView('list'); 

              }).catch((error) => {
                console.log(error.response.data);
              });
            }}

            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            name="file"
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>

         </center>
        </>
    );
}