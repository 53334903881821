import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditCategoriesModal = ({ show, onHide, onConfirm, categories, selectedCategories }) => {
  // Assurez-vous que selectedCategories est initialisé correctement
  const initialSelectedCategories = selectedCategories || [];
  const [editedCategories, setEditedCategories] = useState(initialSelectedCategories);

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    setEditedCategories((prevCategories) => {
      const currentCategories = prevCategories || [];
      let updatedCategories;
      if (checked) {
        updatedCategories = [...currentCategories, value];
      } else {
        updatedCategories = currentCategories.filter((id) => id !== value);
      }
      return updatedCategories;
    });
  };

  const handleConfirm = () => {
    onConfirm(editedCategories);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Modifier les catégories</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div>
            {Array.isArray(categories.categories) ? (
              categories.categories.map((category) => (
                <div key={category.id} className="form-check">
                  <input
                    type="checkbox"
                    id={`category_${category.id}`}
                    value={category.id}
                    checked={editedCategories.includes(category.id)}
                    onChange={handleCategoryChange}
                    className="form-check-input"
                  />
                  <label htmlFor={`category_${category.id}`} className="form-check-label">
                    {category.nom}
                  </label>
                </div>
              ))
            ) : (
              <p>Catégories non disponibles</p>
            )}
          </div>
        </Form>

        {/* Affiche les catégories sélectionnées */}
        <div>
          <h5>Catégories sélectionnées :</h5>
          <ul>
            {editedCategories.map((categoryId) => (
              <li key={categoryId}>
                {categories.categories.find((category) => category.id === categoryId)?.nom}
              </li>
            ))}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Enregistrer les modifications
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCategoriesModal;
