import React from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const UserDetails = ({ show, onHide, user }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Informations sur l'utilisateur</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {user && (
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <strong>ID:</strong>
              </Col>
              <Col xs={12} md={6}>
                {user.id}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <strong>Email:</strong>
              </Col>
              <Col xs={12} md={6}>
                {user.email}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <strong>Nom:</strong>
              </Col>
              <Col xs={12} md={6}>
                {user.nom}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <strong>Prénom:</strong>
              </Col>
              <Col xs={12} md={6}>
                {user.prenom}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <strong>Langue:</strong>
              </Col>
              <Col xs={12} md={6}>
                {user.langue}
              </Col>
            </Row>
            {/* Ajoutez d'autres informations sur l'utilisateur */}
            <Row>
              <Col xs={12} md={6}>
                <strong>Actions:</strong>
              </Col>
              <Col xs={12} md={6}>
                {/* Ajoutez un bouton ou un lien pour gérer les restrictions */}
                <Link to={`/manage-restrictions/${user.id}`}>
                  <Button variant="primary">Gérer les Restrictions</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserDetails;
