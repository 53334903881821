import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, Modal } from "antd";
import { APP_URL } from "../../../URL";
import axios from "axios";
import { subViewState } from "../../../actions/Atom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EditUploadFile = ({ open, chapId }) => {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [subView, setSubView] = useRecoilState(subViewState);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      setFileList([]);
    }
  }, [open]);

  const handleOk = () => {
    setPreviewOpen(false);
  };

  const handleCancel = () => {
    setPreviewOpen(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", marginTop: '200px', marginLeft: '130px' }}>
      <Upload
        customRequest={(file) => {
          axios
            .post(
              `${APP_URL}upload-file-chap/${chapId}`,
              {
                file,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              setFileList(response.data.datas);
              setSubView("list");
              toast.success("Le fichier a été modifié avec succès");
              setPreviewOpen(false); // Fermer le modal après le succès
              navigate("/admin/book-list");
            })
            .catch((error) => {
              console.log(error);
            });
        }}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        name="file"
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>

      <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default EditUploadFile;
