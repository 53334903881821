import { Button, Modal } from "antd";
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { chapIdState } from "../../../actions/Atom";
import { APP_URL } from "../../../URL";
import axios from "axios";
import { connect } from "react-redux";
import { updateChapter } from "../../../actions/ChapitreActions";

const EditChapterModal = ({ visible, onCancel, chapterId, updateChapter, refreshChapterList }) => {
  const [loading, setLoading] = useState(false);
  const [bookData, setBookData] = useState({
    titre: '',
    resume: '',
    type: 'audiobook'
  });

  useEffect(() => {
    const fetchChapitres = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token non trouvé');
      }
      try {
        const response = await axios.get(APP_URL + 'chapter-datas/' + chapterId, {
          headers: {
            'Content-Type': 'application/ld+json',
            'Authorization': `Bearer ${token}`,
          },
        });
        setBookData(response.data.datas);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchChapitres();
  }, [chapterId]);

  const handleEdit = async () => {
    setLoading(true);
    try {
      await updateChapter(chapterId, bookData);
      onCancel(); // Fermer la modal après la mise à jour réussie
      refreshChapterList();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du chapitre :', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBookData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Modal
      title="Modifier le chapitre"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="submit" type="primary" loading={loading} onClick={handleEdit}>
          Enregistrer
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          Annuler
        </Button>
      ]}
    >
      <form onSubmit={(e) => { e.preventDefault(); handleEdit(); }}>
        <label>Titre</label>
        <input required className="form-control" name="titre" value={bookData.titre} onChange={handleChange} />
        <br />
        <label>Biographie</label>
        <textarea required className="form-control" name="resume" value={bookData.resume} onChange={handleChange}></textarea>
        <br />
        <label>Type de fichier</label>
        <select name="type" className="form-control" value={bookData.type} onChange={handleChange}>
          <option value="audioBook">AudioBook</option>
          <option value="ebook">eBook</option>
        </select>
      </form>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateChapter: (chapId, chapterData) => dispatch(updateChapter(chapId, chapterData)),
});

export default connect(null, mapDispatchToProps)(EditChapterModal);
