const initialState = {
  readings: [],
  loading: false,
  error: null,
  currentPageNumber: 1,
  totalPages: 0,
};

  const readingStatsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_READINGS_SUCCESS':
        return {
          ...state,
          loading: false,
          readings: action.payload.datas,
          currentPageNumber: action.payload.currentPageNumber,
          totalPages: action.payload.totalPages,
        };
      case 'FETCH_READINGS_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      default:
        return state;
    }
  };
  
  export default readingStatsReducer;