const initialState = {
    chapitres : [],
    updating: false,
    loading: "",
    error : null,
}

const chapitreReducer = (state= initialState, action ) =>{
    switch(action.type){
        case "DELETE_CHAPITRE_SUCESS":
          return{
            ...state,
            chapitres: state.chapitres.filter((chapitre) => chapitre.id !== action.payload)
          };
          case "DELETE_CHAPITRE_FAILURE":
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
            case "UPDATE_CHAPITRE_SUCCESS":
                return{
                    ...state,
                    loading: false,
                    updating: false,
                    error:null,
                };
                case 'UPDATE_BOOK_FAILURE':
                    return {
                        ...state,
                        updating: false,
                        loading: false,
                        error: action.payload,
                    };
            default:
                return state;  
    }
}

export default chapitreReducer