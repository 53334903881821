import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import "./Pagignate.css";
import {  fetchAllUsers, restoreUser } from '../actions/UserActions';
import { useRecoilState } from "recoil";
import {modalRestoreUser ,modalDataRestoreUser} from '../actions/Atom';
import { Button, Dropdown } from 'antd';
import ConfirmationRestoreModal from './ConfirmationRestoreUserModal';


const UserRestoreList = ({ users, error, loading, fetchAllUsers, restoreUser }) => {
    const usersData = users && users.datas ? users.datas : [];
    const [currentPage, setCurrentPage] = useState(0);
    const [showRestoreModal, setShowRestoreModal] = useRecoilState(modalRestoreUser);
    const [userToRestore, setUserToRestore] = useRecoilState(modalDataRestoreUser);

    useEffect(() =>{
        fetchAllUsers();
    },[fetchAllUsers])
    const usersPerPage = 10;
    const offset = currentPage * usersPerPage;
    
    // Filtrer les utilisateurs actifs
    const activeUsers = usersData.filter((user) => user.actif === false);
   
    const currentUsers = activeUsers.slice(offset, offset + usersPerPage);
    const pageCount = Math.ceil(activeUsers.length / usersPerPage);
    
    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
      };
      const handleShowRestoreModal = (user) => {
        setUserToRestore(user.id);
        setShowRestoreModal(true);
      };
      const handleHideRestoreModal  = () => {
        setUserToRestore(null);
        setShowRestoreModal(false);
      };

      const handleRestoreUser = (user) => {
        if (user) {
          console.log(userToRestore.id)
          restoreUser(userToRestore.id);
          console.log('Utilisateur restore !' ,userToRestore.id);
          handleHideRestoreModal();
        }
      };

      const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
      };
      return (
        <div>
          <h2>Liste des utilisateurs supprimer</h2>
          {loading && <p>Chargement...</p>}
          {error && <p>Erreur: {error}</p>}
          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Email</th>
                <th scope="col">Nom</th>
                <th scope="col">Prenom</th>
                <th scope="col">Genre</th>
                <th scope="col">Date de Naissance</th>
                <th scope="col">Langue</th>
                <th scope="col">Actif</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(currentUsers) && currentUsers.length > 0 ? (
                currentUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.email}</td>
                    <td>{user.nom}</td>
                    <td>{user.prenom}</td>
                    <td>{user.genre}</td>
                    <td>{formatDate(user.anniv)}</td>
                    <td>{user.langue}</td>
                    <td>
                  <span style={{ color: user.actif ? 'green' : 'orange' }}>
                    {user.actif ? 'Actifs' : 'Inactifs'}
                  </span>
                </td>
                    <td>
                      <Dropdown
                     menu = {{
                      items: [
                        {
                          key: '1',
                          label: (
                            <a
                              onClick={() => {
                                handleShowRestoreModal(user.id);
                                setUserToRestore(user);
                              }}
                              style={{ textDecoration: 'none', color: 'green' }}
                              rel="noopener noreferrer"
                              href="javascript:void(0)"
                            >
                              Restorer cette utilisateur
                            </a>
                          ),
                        },
                       
                      ],
                    }}
                      placement="bottomLeft"
                       arrow
                      >
                        <Button>Voir les actions</Button>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">Aucun utilisateur à afficher</td>
                </tr>
              )}
            </tbody>
          </table>
    
          {/* Ajoutez la pagination ici avec ReactPaginate */}
          <ReactPaginate
                previousLabel={'Précédent'}
                nextLabel={'Suivant'}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                previousClassName={'pagination-previous'} 
                nextClassName={'pagination-next'} 
                breakClassName={'pagination-break'} 
                pageClassName={'pagination-item'} 
                activeClassName={'active'}
              />
              <ConfirmationRestoreModal
                    show={showRestoreModal}
                    onHide={handleHideRestoreModal}
                    onConfirm={handleRestoreUser}
                    user={userToRestore}
               />
           
        </div>
    
      );
}

const mapStateToProps = (state) => {
    return {
      users: state.user.users,
      loading: state.user.loading,
      error: state.user.error
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    fetchAllUsers: () => dispatch(fetchAllUsers()),
    restoreUser: (userId) => dispatch(restoreUser(userId)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(UserRestoreList);
  