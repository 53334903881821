// actions/categoryActions.js
import axios from 'axios';
import { toast } from 'react-toastify';
import { APP_URL } from '../URL';

export const createCategory = (categoryData) => {
  return async (dispatch) => {
    try {
        const token = localStorage.getItem('token')
        if (!token) {
            throw new Error('Token non trouvé');
          }
        const response = await axios.post(APP_URL+'categories', categoryData, {
            headers: {
              'Content-Type': 'application/ld+json',
              'Authorization': `Bearer ${token}`, // Assurez-vous que votre API utilise "Bearer" suivi du jeton
            },
          })
      dispatch({
        type: 'CREATE_CATEGORY_SUCCESS',
        payload: response.data,
      });

      // Afficher une notification de succès
      toast.success('Catégorie créée avec succès!');
    }  catch (error) {
      if (error.response) {
        // La requête a été faite, mais le serveur a répondu avec un code d'état différent de 2xx
        console.error('Réponse du serveur avec erreur:', error.response.data);
        console.error('Statut de la réponse:', error.response.status);
        console.error('En-têtes de la réponse:', error.response.headers);
    
        dispatch({
          type: 'CREATE_CATEGORY_FAILURE',
          payload: error.response.data,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la creation des categories');
      } else if (error.request) {
        // La requête a été faite, mais aucune réponse n'a été reçue
        console.error('Pas de réponse reçue du serveur:', error.request);
    
        dispatch({
          type: 'CREATE_CATEGORY_FAILURE',
          payload: 'Pas de réponse reçue du serveur',
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la creation des categories.');
      } else {
        // Une erreur s'est produite lors de la configuration de la requête
        console.error('Erreur lors de la configuration de la requête:', error.message);
    
        dispatch({
          type: 'CREATE_CATEGORY_FAILURE',
          payload: error.message,
        });
    
        // Afficher une notification d'échec
        toast.error('Erreur lors de la creation des categories.');
      }
    }
  };
};

export const fetchCategories = () => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token')
        if (!token) {
            throw new Error('Token non trouvé');
          }
        const response = await axios.get(APP_URL+'categories',{
            headers: {
                'Content-Type': 'application/ld+json',
                'Authorization': `Bearer ${token}`, // Assurez-vous que votre API utilise "Bearer" suivi du jeton
              },
        });
        dispatch({
          type: 'FETCH_CATEGORIES_SUCCESS',
          payload: response.data.datas,
        });
        ;
      } catch (error) {
        if (error.response) {
          // La requête a été faite, mais le serveur a répondu avec un code d'état différent de 2xx
          console.error('Réponse du serveur avec erreur:', error.response.data);
          console.error('Statut de la réponse:', error.response.status);
          console.error('En-têtes de la réponse:', error.response.headers);
      
          dispatch({
            type: 'FETCH_CATEGORIES_FAILURE',
            payload: error.response.data,
          });
      
          // Afficher une notification d'échec
          toast.error('Erreur lors de la lecture des categories');
        } else if (error.request) {
          // La requête a été faite, mais aucune réponse n'a été reçue
          console.error('Pas de réponse reçue du serveur:', error.request);
      
          dispatch({
            type: 'FETCH_CATEGORIES_FAILURE',
            payload: 'Pas de réponse reçue du serveur',
          });
      
          // Afficher une notification d'échec
          toast.error('Erreur lors de la lecture des categories.');
        } else {
          // Une erreur s'est produite lors de la configuration de la requête
          console.error('Erreur lors de la configuration de la requête:', error.message);
      
          dispatch({
            type: 'FETCH_CATEGORIES_FAILURE',
            payload: error.message,
          });
      
          // Afficher une notification d'échec
          toast.error('Erreur lors de la lecture des categories.');
        }
      }
    };
  };

  export const deleteCategory = (categoryId) => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé');
        }
        const response = await axios.delete(`${APP_URL}categorie-delete/${categoryId}`, {
          headers: {
            'Content-Type': 'application/ld+json',
            'Authorization': `Bearer ${token}`,
          }
        });
   
        dispatch({
          type: 'DELETE_CATEGORY_SUCCESS',
          payload: categoryId,
        });
        toast.success('Categorie  a été supprimer avec succès!');
      } catch (error) {
        if (error.response) {
          console.error('Réponse du serveur avec erreur:', error.response.data);
          console.error('Statut de la réponse:', error.response.status);
          console.error('En-têtes de la réponse:', error.response.headers);
      
          dispatch({
            type: 'DELETE_CATEGORY_FAILURE',
            payload: error.message,
          });
      
          // Afficher une notification d'échec
          toast.error('Erreur lors de la suppresion du livre.');
        } else if (error.request) {
          // La requête a été faite, mais aucune réponse n'a été reçue
          console.error('Pas de réponse reçue du serveur:', error.request);
      
          dispatch({
            type: 'DELETE_CATEGORY_FAILURE',
            payload: 'Pas de réponse reçue du serveur',
          });
      
          // Afficher une notification d'échec
          toast.error('Erreur lors de la suppression de la categorie.');
        } else {
          // Une erreur s'est produite lors de la configuration de la requête
          console.error('Erreur lors de la configuration de la requête:', error.message);
      
          dispatch({
            type: 'DELETE_CATEGORY_FAILURE',
            payload: error.message,
          });
      
          // Afficher une notification d'échec
          toast.error('Erreur lors de la suppression de la category.');
        }
      }
    };
  };


  export const updateCategory = (categoryId, updatedCategory) => {

    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé');
        }
        const response = await axios.put(
          `${APP_URL}categories-update/${categoryId}`,
          updatedCategory,
          {
            headers: {
              'Content-Type': 'application/ld+json',
              'Authorization': `Bearer ${token}`,
            },
          }
        )
        dispatch({
          type: 'UPDATE_CATEGORY_SUCCESS',
          payload: response.data,
        });
        toast.success('Categorie  modifié avec succès!');
      } catch (error) {
        if (error.response) {
          console.error('Réponse du serveur avec erreur:', error.response.data);
          console.error('Statut de la réponse:', error.response.status);
          console.error('En-têtes de la réponse:', error.response.headers);
      
          dispatch({
            type: 'UPDATE_CATEGORY_FAILURE',
            payload: error.response.data,
          });
      
          toast.error('Erreur lors de la modification de la categories.');
        } else if (error.request) {
          console.error('Pas de réponse reçue du serveur:', error.request);
      
          dispatch({
            type: 'UPDATE_CATEGORY_FAILURE',
            payload: 'Pas de réponse reçue du serveur',
          });
   
          toast.error('Erreur lors de la création du livre.');
        } else {
         
          console.error('Erreur lors de la configuration de la requête:', error.message);
      
          dispatch({
            type: 'UPDATE_CATEGORY_FAILURE',
            payload: error.message,
          });
          toast.error('Erreur lors de la modfication de la categorie.');
        }
      }
    };
  };
  