import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaTag, FaBook, FaUser } from 'react-icons/fa';


const Sidebar = ({ currentPath, setCurrentPath, handleLogout }) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  
  const navigate = useNavigate();

  const handleItemClick = (path) => {
    setCurrentPath(path);
    // Ajoutez d'autres actions ou logique si nécessaire
  };

  const handleNavbarClick = () => {
    navigate('/admin');
  };

  const showModal = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirmation = () => {
    // Mettez ici la logique de déconnexion après la confirmation
    handleLogout();
    // Fermez le modal
    setShowLogoutModal(false);
  };

  const handleLogoutCancel = () => {
    // Annulez la déconnexion et fermez le modal
    setShowLogoutModal(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-default fixed-top" id="mainNav">
      <div style={{ background: 'white', padding: '5px', marginLeft: '8px', width:'223px', borderRadius:'10px' }}>
        <a className="navbar-brand" href="" onClick={handleNavbarClick} style={{ display: 'flex', alignItems: 'center' }}>
          <b>
            <span style={{ color: 'green', fontSize: '0.9em', marginRight: '5px' }}>AWOULAWOULA</span>
          </b>
          <span style={{ color: 'black', fontSize: '0.8em' }}>- ADMIN</span>
        </a>
      </div>

      <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav navbar-sidenav" id="exampleAccordion">
          <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Dashboard">
            <a className="nav-link" href="" onClick={handleNavbarClick}>
              <i className="fa fa-fw fa-dashboard"></i>
              <span className="nav-link-text">Dashboard</span>
            </a>
          </li>

          <li className="nav-item pl-4" data-toggle="tooltip" data-placement="right" title="Category">
            <div className="d-flex align-items-center">
              <FaTag className="mr-0" />
              <NavDropdown title="Categories" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/admin/create-category" onClick={() => handleItemClick('/admin/create-category')} >
                  Créer une catégorie
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/book-category" onClick={() => handleItemClick('/admin/book-category')}>
                  Liste des catégories
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </li>

          <li className="nav-item pl-4" data-toggle="tooltip" data-placement="right" title="Category">
            <div className="d-flex align-items-center">
              <FaBook className="mr-0" />
              <NavDropdown title="Books" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/admin/create-books" onClick={() => handleItemClick('/admin/create-books')}>
                  Créer un livres
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/book-list" onClick={() => handleItemClick('/admin/book-list')}>
                  Liste les livres
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </li>

          <li className="nav-item pl-4" data-toggle="tooltip" data-placement="right" title="Category">
            <div className="d-flex align-items-center">
              <FaUser className="mr-0" />
              <NavDropdown title="Users" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/admin/user-list" onClick={() => handleItemClick('/admin/user-list')}>
                  Liste Users
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/user-delete" onClick={() => handleItemClick('/admin/user-delete')}>
                  Users Supprimer
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </li>

          <br /><br /><br /><br /><br /><br /><br /><br/>
          </ul>

<ul className="navbar-nav ml-auto">
  <li className="nav-item">
    <form className="form-inline my-2 my-lg-0 mr-lg-2">
      <div className="input-group">
        <input className="form-control search-top" type="text" placeholder="Search for..."/>
        <span className="input-group-btn">
          <button className="btn btn-primary" type="button">
            <i className="fa fa-search"></i>
          </button>
        </span>
      </div>
    </form>
  </li>

  <li className="nav-item">
    <a className="nav-link" onClick={showModal}>
      <i className="fa fa-fw fa-sign-out"></i>Logout
    </a>
  </li>

  <Modal show={showLogoutModal} onHide={handleLogoutCancel}>
    <Modal.Header closeButton>
      <Modal.Title>Confirmation de déconnexion</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Êtes-vous sûr de vouloir vous déconnecter ?
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleLogoutCancel}>
        Annuler
      </Button>
      <Button variant="primary" onClick={handleLogoutConfirmation}>
        Déconnexion
      </Button>
    </Modal.Footer>
  </Modal>
</ul>
</div>
</nav>
);
}

export default Sidebar;
