import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import "./Pagignate.css";
import { deleteUser, fetchAllUsers } from '../actions/UserActions';
import ConfirmationModal from './ConfirmationUserModal';
import UserDetails from './UserDetails';
import { useRecoilState } from "recoil";
import { modalUserDelete,modalDataUserDelete, modalUserDetails,modalDataUserDetails} from '../actions/Atom';
import { Button, Dropdown } from 'antd';



const UsersList = ({ users, error, loading, fetchAllUsers, deleteUser }) => {
  console.log(users);

  // Assurez-vous que users est défini et a la propriété 'datas'
  const usersData = users && users.datas ? users.datas : [];

  const [currentPage, setCurrentPage] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useRecoilState(modalUserDelete);
  const [showDetailsModal, setShowDetailsModal] = useRecoilState(modalUserDetails);
  const [userToDelete, setUserToDelete] = useRecoilState(modalDataUserDelete);
  const [userDetails, setUserDetails] = useRecoilState(modalDataUserDetails);


  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  const usersPerPage = 10;
  const offset = currentPage * usersPerPage;
  
  // Filtrer les utilisateurs actifs
  const activeUsers = usersData.filter((user) => user.actif === true);
  
  const currentUsers = activeUsers.slice(offset, offset + usersPerPage);
  const pageCount = Math.ceil(activeUsers.length / usersPerPage);
  
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleShowDeleteModal = (user) => {
    setUserToDelete(user.id);
    setShowDeleteModal(true);
  };

  const handleHideDeleteModal = () => {
    setUserToDelete(null);
    setShowDeleteModal(false);
  };
  const handleDeleteUser = (user) => {
    if (user) {
      console.log(userToDelete.id)
      deleteUser(userToDelete.id);
      console.log('Utilisateur supprimé !' ,userToDelete.id);
      handleHideDeleteModal();
    }
  };
  const handleShowDetailsModal = (user) => {
    setUserDetails(user);
    setShowDetailsModal(true);
  };

  const handleHideDetailsModal = () => {
    setUserDetails(null);
    setShowDetailsModal(false);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
  };

  return (
    <div>
      <h2>Liste des utilisateurs</h2>
      {loading && <p>Chargement...</p>}
      {error && <p>Erreur: {error}</p>}
      <table className="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Email</th>
            <th scope="col">Nom</th>
            <th scope="col">Prenom</th>
            <th scope="col">Genre</th>
            <th scope="col">Date de Naissance</th>
            <th scope="col">Langue</th>
            <th scope="col">Actif</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(currentUsers) && currentUsers.length > 0 ? (
            currentUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.email}</td>
                <td>{user.nom}</td>
                <td>{user.prenom}</td>
                <td>{user.genre}</td>
                <td>{formatDate(user.anniv)}</td>
                <td>{user.langue}</td>
                <td>
                  <span style={{ color: user.actif ? 'green' : 'orange' }}>
                    {user.actif ? 'Actifs' : 'Inactifs'}
                  </span>
                </td>
                <td>
                  <Dropdown
                 menu = {{
                  items: [
                    {
                      key: '1',
                      label: (
                        <a
                          onClick={() => {
                            handleShowDeleteModal(user.id);
                            setUserToDelete(user);
                          }}
                          style={{ textDecoration: 'none', color: 'red' }}
                          rel="noopener noreferrer"
                          href="javascript:void(0)"
                        >
                          Supprimer cette utilisateur
                        </a>
                      ),
                    },
                    {
                      key: '2',
                      label: (
                        <a
                          onClick={() => {
                            handleShowDetailsModal();
                            setUserDetails(user);
                          }}
                          style={{textDecoration:'none'}}
                          rel="noopener noreferrer"
                          href="javascript:void(0)"
                        >
                           voir detail utilisateur
                        </a>
                      ),
                    },
                  ],
                }}
                  placement="bottomLeft"
                   arrow
                  >
                    <Button>Voir les actions</Button>
                  </Dropdown>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">Aucun utilisateur à afficher</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Ajoutez la pagination ici avec ReactPaginate */}
      <ReactPaginate
            previousLabel={'Précédent'}
            nextLabel={'Suivant'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            previousClassName={'pagination-previous'} 
            nextClassName={'pagination-next'} 
            breakClassName={'pagination-break'} 
            pageClassName={'pagination-item'} 
            activeClassName={'active'}
          />
          <ConfirmationModal
        show={showDeleteModal}
        onHide={handleHideDeleteModal}
        onConfirm={handleDeleteUser}
        user={userToDelete}
      />
       <UserDetails
        show={showDetailsModal}
        onHide={handleHideDetailsModal}
        user={userDetails}
      />
    </div>

  );
};

const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    loading: state.user.loading,
    error: state.user.error
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllUsers: () => dispatch(fetchAllUsers()),
  deleteUser: (userId) => dispatch(deleteUser(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
