const initialState = {
    currentPath: "/admin", 
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CURRENT_PATH":
          return {
            ...state,
            currentPath: action.payload,
          };
        default:
          return state;
      }
}

export default appReducer