import { useRecoilState } from "recoil";
import { modalChapterUpdateState, modalDataChapterUpdateState, subViewState } from "../../actions/Atom";
import { useState } from "react";
import { Button, Modal, Spin } from "antd";
import ListChap from "./sub_views/ListChap";
import AddChap from "./sub_views/AddChap";
import Intro from "./sub_views/Intro";
import { AlignLeftOutlined, PlusOutlined } from "@ant-design/icons";
import UploadFile from "./sub_views/UploadFile";

export default function ShowChapterList(){

    const [isModalOpenChapter, setIsModalOpenChapter] = useRecoilState(modalChapterUpdateState);
    const [modalDataChapter, setModalDataChapter] = useRecoilState(modalDataChapterUpdateState);
    const [subView, setSubView] = useRecoilState(subViewState);

    const [chapitre, setChapitre] = useState([]);
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(true);

    const showModal = () => {
        setIsModalOpenChapter(true);
    };
    const handleOk = () => {
        setIsModalOpenChapter(false);
    };
    const handleCancel = () => {
        setIsModalOpenChapter(false);
    };

    return (
        <div >
                <Modal
                    title={`Chapitres du livre "${modalDataChapter.titre}"`}
                    width={600}
                    height={700} // 'height' est corrigé en 'height' au lieu de 'heigth'
                    style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '100px' }} // Centrer horizontalement et définir la marge supérieure
                    open={isModalOpenChapter} // 'open' est corrigé en 'visible'
                    footer={null}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                <div>
                    <Button type="primary" onClick={() => setSubView('add_chap')} className="" > <PlusOutlined /> Ajouter un nouveau chapitre</Button>
                    &nbsp;&nbsp;
                    <Button type="primary" onClick={() => setSubView('list')} className="" > <AlignLeftOutlined /> Liste des chapitres</Button>
                    <hr />

                    {subView === 'list' && <ListChap livre={modalDataChapter} />}
                    {subView === 'add_chap' && <AddChap livre={modalDataChapter} />}
                    {subView === 'uploadFile' && <UploadFile livre={modalDataChapter} />}
                </div>
            </Modal>
        </div>
    );
}
