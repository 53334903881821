// reducers/statisticsReducer.js

const initialState = {
    nb_livre: 0,
    nb_enfant: 0,
    nb_parent: 0,
    parent_list: [],
    enfant_list: [],
    loading: false,
    error: null,
  };
  
  const statisticsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_STATISTICS_SUCCESS':
        return {
          ...state,
          ...action.payload.data,
          loading: false,
          error: null,
        };
      case 'FETCH_STATISTICS_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default statisticsReducer;
  