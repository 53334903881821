import { UPLOAD_PDF_FAILURE, UPLOAD_PDF_SUCCESS,UPLOAD_PDF_PROGRESS } from "../actions/TypesActions";

const initialState = {
    uploadedpdf: null,
    error: null,
    uploadProgress: 0,
  };

  const fileReducer = (state = initialState, action)=>{
    switch(action.type){
        case 'UPLOAD_PDF_SUCCESS':
            return {
                ...state,
                uploadedpdf: action.payload,
                error: null,
              };
              case 'UPLOAD_PDF_FAILURE':
                return {
                  ...state,
                  uploadedpdf: null,
                  error: action.payload,
                };
                case "UPLOAD_PDF_PROGRESS":
                    return {
                      ...state,
                      uploadProgress: action.payload,
                    };
                default:
                    return state;
        }
 };
              
     export default fileReducer;